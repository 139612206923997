// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


body {
  padding-top: $navbar-height + 10px;
}
